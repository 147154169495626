<template>
	<div class="list">
		<el-dialog title="详情" :visible.sync="dialogVisible" width="30%">
			<span>这是一段信息</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<Card style='margin-left: 200px'>
			<div style="height: 600px;">
				<div class="titles">商品列表</div>
				<div class="title">
					<slot></slot>
				</div>
				<div class="content">
					<div id="contents">
						<div class="cont">
							<table>
								<tr>
									<td class='titNa' v-for="item,index in titles" :key='index'>{{item}}</td>
								</tr>
								<tr v-for="item2,index2 in contentC" :key='index2'>
									<td>{{item2.glname}}</td>
									<td class="img"><img v-if="item2.glimg!=null" :src="uploadingUrl1 + item2.glimg" ></td>
									<td>商品</td>
									<td class="oper">
										<div class="com" @click="compile(item2.glid,item2.glname,item2.glimg,item2.storeid)">编辑</div>
										<div class="del" @click="del(item2.glid)">删除</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Card>
		<Modal v-model="modal1" title="添加类型" @on-ok="ok" @on-cancel="cancel">
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
				<FormItem label="标签名" prop="name">
					<Input v-model="formValidate.name" placeholder="请输入名称"></Input>
				</FormItem>
				<FormItem label="所属店铺" prop="city" v-if='typeFlag!=3'>
					<Select v-model="storeid" placeholder="请选择">
						<Option :value="item.storeid" v-for='item,index in list' :key='index'>{{item.s_name}}</Option>
					</Select>
				</FormItem>
				<el-upload class="avatar-uploader" :action="uploadingUrl2" :show-file-list="false" :on-success="handleAvatarSuccess"
				 :before-upload="beforeAvatarUpload">
					<img v-if="imageUrl" :src="imageUrl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					<div slot="tip" class="el-upload__tip">分类图标只能上传jpg/png文件，且不超过500kb</div>
				</el-upload>
			</Form>
		</Modal>
	</div>
</template>

<script>
	export default {
		name: 'contents',
		props: {
			contentC: {
				type: Array,
			},
			titles: {
				type: Array,
			},
			flag: {
				type: Number,
			}
		},
		data() {
			return {
				social: [],
				dialogVisible: false,
				storeid:'',
				uploadingUrl1: '',
				modal1: false,
				formValidate: {
					name: '',
					city: ''
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]
				},
				typeFlag: null,
				list:null,
				uploadingUrl2: '',
				imageUrl: '',
				logo: null,
				id: null
			}
		},
		created() {
			this.uploadingUrl1 = this.$request.imgUrl2()+'uploads/';
			let token = this.$storage.getLocal('token');
			this.typeFlag = this.$storage.getLocal('type');
			this.uploadingUrl2 = this.$request.imgUrl() + 'PHPExcel/fileUpload';
			this.$request.getNextSelectList({token}).then(res => {
				if (res.code == 0) {
					this.list = res.data;
				}
			})
		},
		components: {

		},
		methods: {
			handleReset(name) {
				this.$refs[name].resetFields();
				this.imageUrl = ''
			},
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw);
				this.logo = res.data.src;
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			
			
			start(id,start) {
				let token = this.$storage.getLocal('token');
				this.$request.goodsIsGrounding({
					token,
					id,
					grounding: start==2 ? 1 : 2,
				}).then(res => {
					if (res.code == 0) {
						this.$notify({
							title: '成功',
							message: '成功',
							type: 'success'
						});
						this.$emit("del", 'ok');
					} else {
						this.$notify({
							title: '警告',
							message: '失败',
							type: 'warning'
						})
					}
				})
			},
			del(id) { //删除
				this.$confirm('此操作将永久删除该用户,不可恢复,是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then((res) => {
					let token = this.$storage.getLocal('token');
					this.$request.delLabelData({
						id,
						token
					}).then(res => {
						if (res.code == 0) {
							this.$emit("del", 'ok');
						} else {
							this.$emit("del", 'ok');
							this.$notify({
								title: '失败',
								message: '删除失败',
								type: 'warning'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			examine(id) { //查看
				this.dialogVisible = true
			},
			log(id) { //日志
				console.log(id)
			},
			compile(id,name,img,storeid) { //编辑
			console.log()
				this.modal1 = true;
				this.formValidate.name = name;
				this.imageUrl = this.$request.imgUrl2()+'uploads/'+img;
				this.logo = img;
				this.id = id;
				this.storeid=storeid
			},
			cancel() {
				this.$Message.info('取消添加');
			},
			ok() {
				let token = this.$storage.getLocal('token');
				let id = this.$storage.getLocal('type')
				this.$refs['formValidate'].validate((valid) => {
					if (valid) {
						this.$request.upLabelData({
							token,
							name: this.formValidate.name,
							img: this.logo,
							glid: this.id
						}).then(res => {
							if (res.code == 0) {
								this.$Message.success('添加成功!');
								this.$emit("del", 'ok');
								this.$refs['formValidate'].resetFields();
							}
						})
					} else {
						this.$Message.error('添加失败请输入名称！！！');
					}
				})
			}
		}
	}
</script>

<style scoped="scoped">
	table {
		width: 100%;
		background: #eeeeee;
	}

	td {
		background-color: #FFFFFF;
		font-size: 15px;
		height: 30px;
		/* width: 15%; */
	}

	tr .titNa {
		background-color: #F9FAFC;
		font-size: 15px;
		font-weight: 800;
	}

	.oper {
		display: flex;
		justify-content: space-around;
		color: #42B983;
	}
	.img img{
		width: 20px;
	}
	.add {
		height: 50px;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 58px;
		height: 58px;
		line-height: 58px;
		text-align: center;
	}
	
	.avatar {
		width: 58px;
		height: 58px;
		display: block;
	}
	
	.avatar-uploader-icon {
		border: 1px solid #333333;
	}
	.avatar-uploader{
		text-align: center;
	}
</style>
