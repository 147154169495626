<template>
	<div class="sign">
		<frame :top1="top1" :top2="top2">
			<lists :titles='titles' :contentC='datas' @add='refresh()' @del='refresh()'>
				<div class="title">
					<div class="listcont">
					<div class="shuai" v-if="typeFlag==1">
					<div class="selname">商家类型:</div>
					<Select  placeholder="请选择" class="sel">
											<!-- <Option   value="">全部</Option> -->
					<Option  :value="item.storeid" v-for='item,index in selects' :key='index' @click.native="shopType(item.storeid)">{{item.s_name}}</Option>
					 </Select>
					</div>	
					<div class="shuai" v-if="typeFlag==1||typeFlag==2">
					<div class="selname">店铺类型:</div>
					<Select  placeholder="请选择" class="sel">
											<!-- <Option   value="">全部</Option> -->
					<Option  :value="item.storeid" v-for='item,index in selectp' :key='index' @click.native="storeidType(item.storeid)">{{item.s_name}}</Option>
					 </Select>
					</div>	
					<div class="input">
						<Button type="success" @click='search(xstoreid)'>搜索</Button>
					</div>
					</div>
					<div class="add">
						<el-button type="warning" plain @click='addType()'>添加</el-button>
					</div>
				</div>
			</lists>
		</frame>
		<Modal v-model="modal1" title="添加类型" @on-ok="ok" @on-cancel="cancel">
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
				<FormItem label="标签名" prop="name">
					<Input v-model="formValidate.name" placeholder="请输入名称"></Input>
				</FormItem>
				<FormItem label="所属店铺" prop="city" v-if='typeFlag!=3'>
					<Select v-model="formValidate.city" placeholder="请选择">
						<Option :value="item.storeid" v-for='item,index in list' :key='index'>{{item.s_name}}</Option>
					</Select>
				</FormItem>
				<el-upload class="avatar-uploader" :action="uploadingUrl" :show-file-list="false" :on-success="handleAvatarSuccess"
				 :before-upload="beforeAvatarUpload">
					<img v-if="imageUrl" :src="imageUrl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					<div slot="tip" class="el-upload__tip">分类图标只能上传jpg/png文件，且不超过500kb</div>
				</el-upload>
			</Form>
		</Modal>
	</div>
</template>

<script>
	import lists from './componSign/SignList.vue';
	import frame from '../public/Frame.vue';
	export default {
		name: 'sign',
		data() {
			return {
				top1: '4-5',
				top2: ['4'],
				titles: ['标签', '图片', '适用对象', '操作'],
				datas: null,
				modal1: false,
				selectp:'',
				selects:'',
				xstoreid:'',
				typeFlag:'',
				formValidate: {
					name: '',
					city: ''
				},
				ruleValidate: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]
				},
				typeFlag: null,
				list:null,
				uploadingUrl: '',
				imageUrl: '',
			}
		},
		components: {
			frame,
			lists
		},
		created() {
			let token = this.$storage.getLocal('token');
			this.typeFlag = this.$storage.getLocal('type');
			this.uploadingUrl = this.$request.imgUrl() + 'PHPExcel/fileUpload';
			// this.$request.labelAddData({token,})
			this.$request.getNextSelectList({token}).then(res => {
				if (res.code == 0) {
					this.list = res.data;
				}
			})
			
			this.$request.labelListData({
				token,
				type: this.$storage.getLocal('type'),
				storeid:''
			}).then(res => {
				if (res.code == 0) {
					this.datas = res.data;
					// this.num = res.data.count;
				}
			})
			if(this.typeFlag=='1')
			{
				this.$request.getMerchantData({token}).then(r=>{
					console.log(123)
					if(r.code == 0){ 
						this.selects = r.data;
						console.log(r)
				
					}
				})
			}else if(this.typeFlag=='2')
			{
				this.$request.getStoreDataList({token,pstoreid:'',login_type:this.$storage.getLocal('type')}).then(r=>{
					console.log(123)
					if(r.code == 0){ 
						this.selectp = r.data;
						console.log(r)
					}
				})
			}
		},
		methods:{
				handleReset(name) {
					this.$refs[name].resetFields();
					this.imageUrl = ''
				},
				handleAvatarSuccess(res, file) {
					this.imageUrl = URL.createObjectURL(file.raw);
					this.logo = res.data.src;
				},
				beforeAvatarUpload(file) {
					const isJPG = file.type === 'image/jpeg';
					const isLt2M = file.size / 1024 / 1024 < 2;
					if (!isJPG) {
						this.$message.error('上传头像图片只能是 JPG 格式!');
					}
					if (!isLt2M) {
						this.$message.error('上传头像图片大小不能超过 2MB!');
					}
					return isJPG && isLt2M;
				},
				shopType(value){
					console.log(111)
					let token = this.$storage.getLocal('token');
					this.$request.getStoreDataList({token,pstoreid:value,login_type:this.$storage.getLocal('type')}).then(r=>{
						console.log(123)
						if(r.code == 0){ 
							this.selectp = r.data;
							console.log(r)
						}
					})
				},
				storeidType(value)
				{
					this.xstoreid=value
				},
				search(val)
				{
					let token = this.$storage.getLocal('token');
					this.$request.labelListData({
						token,
						type: this.$storage.getLocal('type'),
						storeid:val
					}).then(res => {
						if (res.code == 0) {
							this.datas = res.data;
							// this.num = res.data.count;
						}
					})
				},
				addType() {
					// console.log("s")
					this.modal1 = true
				},
				ok() {
					let token = this.$storage.getLocal('token');
					let id = this.$storage.getLocal('type')
					this.$refs['formValidate'].validate((valid) => {
						if (valid) {
							this.$request.labelAddData({
								token,
								name: this.formValidate.name,
								type: this.typeFlag,
								img: this.logo,
								storeid: this.formValidate.city
							}).then(res => {
								if (res.code == 0) {
									this.$Message.success('添加成功!');
									this.refresh();
									this.$refs['formValidate'].resetFields();
								}
							})
						} else {
							this.$Message.error('添加失败请输入名称！！！');
						}
					})
				},
				cancel() {
					this.$Message.info('取消添加');
				},
				refresh() {
					let token = this.$storage.getLocal('token');
					this.$request.labelListData({
						token,
						type: this.$storage.getLocal('type')
					}).then(res => {
						if (res.code == 0) {
							this.datas = res.data;
						}
					})
				}
		}
	}
</script>

<style scoped="scoped">
	.title {
		width: 88%;
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		height: 50px;
		line-height: 50px;
	}
	
	.add {
		height: 50px;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 58px;
		height: 58px;
		line-height: 58px;
		text-align: center;
	}
	
	.avatar {
		width: 58px;
		height: 58px;
		display: block;
	}
	
	.avatar-uploader-icon {
		border: 1px solid #333333;
	}
	.avatar-uploader{
		text-align: center;
	}
	.shuai
	{
		display: flex;
		/* border:1px solid black */
	}
	.selname
	{
		width: 90%;
	}
	.sel
	{
		margin-left: 10px;
		margin-top: 5px;
	}
	.listcont
	{
		display: flex;
		}
</style>
